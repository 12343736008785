<template>
  <div>
    <div class="card-content">
      <div class="columns is-centered is-multiline is-mobile">
        <div class="column is-12">
          <p class="is-size-4 is-size-6-mobile" v-html="rangeTitle"></p>
          <p class="is-size-7">Drag the sliders to choose a range</p>
        </div>
        <div class="column is-11 has-text-centered" v-if="filterOption">
          <VueSlider
            class="slider"
            dot-size="33"
            :min="filterOption.min"
            :max="filterOption.max"
            :interval="1"
            :enable-cross="false"
            v-model="range"
          />
        </div>
      </div>
    </div>
    <div class="card-footer has-background-white">
      <div class="column is-12">
        <a v-if="range" class="has-text-danger" @click="clearFilters">
          Reset
        </a>
        <p v-else class="has-text-grey-light">Reset</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { formatNumber, formatMoney } from 'accounting'
import 'vue-slider-component/theme/antd.css'
import { debounce } from 'lodash'
export default {
  name: 'StockviewSliderFilter',
  components: {
    VueSlider: () => import('vue-slider-component')
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    filter: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    range: []
  }),
  watch: {
    range: 'setFilterState'
  },
  mounted() {
    const filterMin = this.filters[this.filterMin]
    const filterMax = this.filters[this.filterMax]
    const { min: apiMin, max: apiMax } = this.filterOption
    const min = filterMin !== null && filterMin > apiMin ? filterMin : apiMin
    const max = filterMax !== null && filterMax < apiMax ? filterMax : apiMax

    this.range = [min, max]
  },
  computed: {
    ...mapGetters('stockview/listings', ['filters', 'filterOptions']),
    rangeTitle() {
      const map = {
        mileage: `${formatNumber(this.range[0])} miles to
          ${formatNumber(this.range[1])} miles`,
        daysInStock: `${formatNumber(this.range[0])} to
          ${formatNumber(this.range[1])} days in stock`,
        price: `${formatMoney(this.range[0], '£', 0)} to
          ${formatMoney(this.range[1], '£', 0)}`,
        desirability: `<span class="${this.getColour(0)}">
          ${this.range[0]}</span> - <span class="${this.getColour(1)}">
          ${this.range[1]}</span>`
      }
      return map[this.key]
    },
    filterOption() {
      return this.filterOptions[this.filter.key]
    },
    filterMin() {
      return `${this.key}Min`
    },
    filterMax() {
      return `${this.key}Max`
    },
    key() {
      return this.filter.key
    }
  },
  methods: {
    ...mapActions('stockview/listings', ['updateFilters']),
    track(min, max, apiMin, apiMax) {
      const action = min === apiMin && max === apiMax ? 'reset' : 'update'
      this.$mxp.track(`stockview_stock_${action}_${this.key}_filter`)
    },
    setFilterState: debounce(async function() {
      const { key, filterMin, filterMax } = this
      const type = key
      const selectedMin = this.range[0]
      const selectedMax = this.range[1]
      const { min, max } = this.filterOption
      const count = selectedMin === min && selectedMax === max ? 0 : 1
      const filters = [
        { type: filterMin, payload: selectedMin },
        { type: filterMax, payload: selectedMax }
      ]
      this.track(selectedMin, selectedMax, min, max)
      this.updateFilters({ filters, count, type })
    }, 500),
    clearFilters() {
      const { min, max } = this.filterOption
      this.range = [min, max]
    },
    getColour(position) {
      if (this.range[position] >= 67) return 'has-text-success'
      if (this.range[position] >= 34) return 'has-text-warning'
      return 'has-text-danger'
    }
  }
}
</script>
